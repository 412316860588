import.meta.glob([
    '../images/**',
    //'../fonts/**',
]);

import './bootstrap';
import 'preline';

import { Livewire, Alpine } from './../../vendor/livewire/livewire/dist/livewire.esm';

//import customPlugin from './plugins/custom-plugin'
import ToastComponent from './../../vendor/usernotnull/tall-toasts/resources/js/tall-toasts'

//Alpine.plugin(customPlugin)
Alpine.plugin(ToastComponent)

Livewire.start()
